import React from "react";
import SEO from "components/seo";
import { Services } from "components/Services";
import { Clients, ContactPerson } from "sections/Homepage";
import { data_solutions } from "../data/solutions";
import { useContactPersonData } from "shared/Hooks/ContactPerson";
import { TitleL } from "components/Typography";
import { TextBox, Text } from "components/Services/styles";
import { BigHero } from "components/BigHero";
import useHerosTypesData from "../shared/Hooks/PageHeaders/useHerosTypesData";
import { SOLUTIONS } from "../shared/Types/heros";

const solutions_text = {
  title:
    "Tak jak szeroki jest Wszechświat, tak szeroki jest zakres naszych działań.",
  text:
    "Nasza oferta nie skupia się tylko na jednej branży. Współpracujemy z większymi i mniejszymi firmami, klientami indywidualnymi, inwestorami czy dealerami samochodowymi. Stań się jedną z planet naszego układu.",
};

const Solutions = () => {
  const data = useHerosTypesData(SOLUTIONS);

  return (
    <>
      <SEO title={data.title} description={data.seo} />
      <BigHero data={data} />
      <Services data={data_solutions}>
        <TextBox>
          <TitleL>{solutions_text.title}</TitleL>
          <Text>{solutions_text.text}</Text>
        </TextBox>
      </Services>
      <Clients />
      {/*<ContactPerson personData={useContactPersonData().jakub_furman} />*/}
    </>
  );
};

export default Solutions;
