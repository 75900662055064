import BuissnesIcon from "assets/images/buissnes.svg";
import CarIcon from "assets/images/car.svg";
import HouseIcon from "assets/images/house.svg";
import EcommerceIcon from "assets/images/ecommerce.svg";

export const data_solutions = [
  {
    icon: BuissnesIcon,
    title: "Biznesy B2B",
    text:
      "Przekonaj potencjalnych partnerów do pokochania Twojego biznesu. Nie wiesz, jak to zrobić? Chętnie pomożemy!",
    href: "/b2b",
  },
  {
    icon: CarIcon,
    title: "Automotive",
    text:
      "Wiemy, jak pozyskiwać leady dla branży motoryzacyjnej. Pokierujemy działaniami wizerunkowymi i sprzedażowymi.",
    href: "/moto",
  },
  {
    icon: HouseIcon,
    title: "Deweloperzy",
    text:
      "Zaprezentuj swoją inwestycję jak najlepiej. Dobra reklama Twojej nieruchomości to podstawa generowania sprzedaży.",
    href: "/deweloper",
  },
  {
    icon: EcommerceIcon,
    title: "E-commerce",
    text:
      "Każdy sklep internetowy potrzebuje odpowiedniego wsparcia. Zobacz, co możemy Ci zaproponować.",
    href: "/b2c",
  },
];
